import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { LEFT, RIGHT, ENTER, BACK } from "../constants/keyCodes";
import Cookies from "js-cookie";
import { clearData } from "../store/actions";

function Account() {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const back = useRef(null);
  const logout = useRef(null);

  useEffect(() => {
    if (back.current) back.current.focus();
  }, [back]);

  const handleLogout = () => {
    const token = JSON.parse(Cookies.get("authToken"));

    fetch(`https://api.nctvn.com/device/${user?.device.deviceCode}`, {
      method: "DELETE",
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        clearData();
        Cookies.remove("authToken");
        setTimeout(() => {
          history.goBack();
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleKeyDownForBack = (e) => {
    switch (e.which) {
      case ENTER:
        history.goBack();
        break;
      case RIGHT:
        logout.current.focus();
        break;
      case BACK:
        window.history.pushState("backhandler", null, null);
        history.goBack();
        break;
    }
  };

  const handleKeyDownForLogout = (e) => {
    switch (e.which) {
      case ENTER:
        handleLogout();
        break;
      case LEFT:
        back.current.focus();
        break;
      case BACK:
        window.history.pushState("backhandler", null, null);
        history.goBack();
        break;
    }
  };

  return (
    <div className="bg-primary flex justify-center items-center w-screen h-screen overflow-auto">
      <div className="w-1/3 px-7">
        <img src={logo} alt="" className="mx-auto mb-16" />
        <div className="border-b border-t border-gray-700">
          <div className="my-7 ">
            <h3 className="text-lg my-2 text-gray-300">Name:</h3>
            <p className="text-xl capitalize">{`${user?.user?.firstName} ${user?.user?.middleName} ${user?.user?.lastName}`}</p>
          </div>

          <div className="my-7 ">
            <h3 className="text-lg my-2 text-gray-300">Email:</h3>
            <p className="text-xl">{user?.user?.email}</p>
          </div>

          <div className="my-7">
            <h3 className="text-lg my-2 text-gray-300">Subscription:</h3>
            <p className="text-xl capitalize">{user?.user?.subscriptions}</p>
          </div>

          <div className="my-7 ">
            <h3 className="text-lg my-2 text-gray-300">Devices:</h3>
            <p className="text-xl">
              {user?.device.devices
                .map((item) => `${item.deviceCode} (${item.type})`)
                .join(", ")}
            </p>
          </div>
        </div>
        <div className="my-8 flex justify-between px-2">
          <button
            className="border focus:text-white py-2 rounded-full w-5/12 focus:bg-activeIcon focus:outline-none text-primary border-selectedItem"
            onKeyDown={handleKeyDownForBack}
            ref={back}
            tabIndex={11}
          >
            BACK
          </button>
          <button
            className="border focus:text-white py-2 rounded-full w-5/12 focus:bg-activeIcon focus:outline-none text-primary border-selectedItem"
            onKeyDown={handleKeyDownForLogout}
            ref={logout}
            tabIndex={12}
          >
            LOG OUT
          </button>
        </div>
      </div>
      <p className="absolute bottom-10 left-10">App Version: 1.0.21</p>
      <p className="absolute bottom-10 right-10 text-xl tracking-wider">
        {`Current Device: ${user?.device?.deviceCode}`}
      </p>
    </div>
  );
}

export default Account;
