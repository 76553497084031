import React, { useEffect, useRef } from "react";
import { UP, DOWN, ENTER, BACK } from "../constants/keyCodes";
import { useDispatch } from "react-redux";
import { handleExitPopupVisibility } from "../store/actions";
import { useLocation } from "react-router-dom";

function ExitPopup() {
  const ok = useRef(null);
  const cancel = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (ok.current) {
      // setTimeout(() => {
      ok.current.focus();
      // }, 300);
    }
  }, []);

  const handleKeyDownForOk = (e) => {
    e.preventDefault();
    switch (e.which) {
      case ENTER:
        window.open("", "_self").close();
        break;
      case DOWN:
        cancel.current.focus();
        break;
      case BACK:
        if (window.history.state !== "backhandler") {
          dispatch(handleExitPopupVisibility(false));

          if (location.pathname === "/pair")
            document.getElementById("pair").focus();
          else document.getElementById("list-container").focus();

          window.history.pushState("backhandler", null, null);
        }
        // setTimeout(() => {
        //   dispatch(handleExitPopupVisibility(false));
        //   document.getElementById("list-container").focus();
        // }, 500);
        break;
    }
  };

  const handleKeyDownForCancel = (e) => {
    e.preventDefault();
    switch (e.which) {
      case ENTER:
        dispatch(handleExitPopupVisibility(false));
        if (location.pathname === "/pair")
          document.getElementById("pair").focus();
        else document.getElementById("list-container").focus();
        break;
      case UP:
        ok.current.focus();
        break;
      case BACK:
        if (window.history.state !== "backhandler") {
          dispatch(handleExitPopupVisibility(false));

          if (location.pathname === "/pair")
            document.getElementById("pair").focus();
          else document.getElementById("list-container").focus();

          window.history.pushState("backhandler", null, null);
        }
        // setTimeout(() => {
        //   dispatch(handleExitPopupVisibility(false));
        //   document.getElementById("list-container").focus();
        // }, 500);
        break;
    }
  };

  return (
    <div
      className="absolute w-screen h-screen flex justify-center items-center z-50 "
      style={{ background: "#ffffff22" }}
    >
      <div className="h-auto w-5/12 bg-primary flex flex-col items-center py-12 rounded-2xl">
        <p className="text-white mb-5 text-3xl">Exit the NCTVN Application</p>
        <p className="text-white mb-7 text-2xl">
          Do you want to EXIT the NCTVN app?
        </p>

        <button
          className="exit-popup-buttons"
          ref={ok}
          onKeyDown={handleKeyDownForOk}
          tabIndex={9}
          id="exit"
          style={{ fontSize: "1.5rem" }}
        >
          Exit
        </button>
        <button
          className="exit-popup-buttons"
          ref={cancel}
          onKeyDown={handleKeyDownForCancel}
          tabIndex={10}
          style={{ fontSize: "1.5rem" }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ExitPopup;
