import {
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  FETCH_VIDEOS_FAILURE,
  VIDEO_PLAYLIST,
  SHOW_EXIT_POPUP,
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
  STORE_USER,
  SET_HERO_IMAGE,
  SELECT_TV_SHOW,
  CLEAR_DATA,
} from "../actionTypes";
import Cookies from "js-cookie";

const fetchvideosRequest = () => ({ type: FETCH_VIDEOS_REQUEST });
const fetchvideosSuccess = (payload) => ({
  type: FETCH_VIDEOS_SUCCESS,
  payload,
});
const fetchvideosFailure = (errors) => ({ type: FETCH_VIDEOS_FAILURE, errors });

export const fetchVideos = (url, id) => async (dispatch) => {
  // const token = JSON.parse(Cookies.get("authToken"));
  dispatch(fetchvideosRequest());
  let _data = localStorage.getItem(id + "_data");
  let _time = localStorage.getItem(id + "_time");
  if (_data && _time > new Date().getTime()) {
    // if (false) {
    setTimeout(() => {
      return dispatch(fetchvideosSuccess(JSON.parse(_data)));
    }, 2000);
  } else {
    try {
      const response = await fetch(url, {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${token.access_token}`,
        // },
      }).then((res) => res.json());

      // console.log(response);
      localStorage.setItem(id + "_data", JSON.stringify(response));
      localStorage.setItem(
        id + "_time",
        new Date().getTime() + 6 * 60 * 60 * 1000
      );

      if (response) {
        return dispatch(fetchvideosSuccess(response));
      }

      return dispatch(fetchvideosFailure(response.body.message));
    } catch (e) {
      return dispatch(fetchvideosFailure(e));
    }
  }
};

const fetchTokenRequest = () => ({ type: FETCH_TOKEN_REQUEST });
const fetchTokenSuccess = (payload) => ({
  type: FETCH_TOKEN_SUCCESS,
  payload,
});
const fetchTokenFailure = (errors) => ({ type: FETCH_TOKEN_FAILURE, errors });

export const fetchUserToken = (email) => async (dispatch) => {
  dispatch(fetchTokenRequest());
  try {
    const response = await fetch("https://api.nctvn.com/user", {
      method: "POST",
      body: JSON.stringify({ emailId: email }),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    console.log(response);
    if (response.status === "SUCCESS") {
      Cookies.set("authToken", JSON.stringify(response.token), {
        expires: response.token.expires_in,
      });
      dispatch(fetchVideos());
      return dispatch(fetchTokenSuccess(response));
    }

    return dispatch(fetchTokenFailure("Error"));
  } catch (e) {
    return dispatch(fetchTokenFailure(e));
  }
};

export const playVideo = (playlist) => ({
  type: VIDEO_PLAYLIST,
  payload: playlist,
});

export const handleExitPopupVisibility = (show) => ({
  type: SHOW_EXIT_POPUP,
  payload: show,
});

export const storeUser = (user) => ({
  type: STORE_USER,
  payload: user,
});

export const setHeroImage = (img) => ({
  type: SET_HERO_IMAGE,
  payload: img,
});

export const selectTvShow = (show) => ({
  type: SELECT_TV_SHOW,
  payload: show,
});

export const clearData = () => ({
  type: CLEAR_DATA,
});
