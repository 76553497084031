import React, { useEffect } from "react";
import CashCreek from "../assets/images/bg.jpg";
import RaleighJones from "../assets/images/bg2.jpeg";
import Cia from "../assets/images/bg3.png";
import MainImg from "../assets/images/ss.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  // storeUser,
  // fetchUserToken,
  fetchVideos,
  // selectTvShow,
} from "../store/actions";
// import { generateDeviceId } from "../Utils";
// import Cookies from "js-cookie";
import loader from "../assets/images/loader.svg";
import { IS_MAIN_APP } from "../store/actionTypes";

function SplashScreen() {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const videos = useSelector((state) => state.videos);

  const queryParams = new URLSearchParams(location.search);
  // const name = queryParams.get("name");
  const id = queryParams.get("id");
  let name = "";
  let queryName = "";
  if (id == 6230821) {
    name = "J Raleigh Jones Show";
    queryName = "jrj";
  } else if (id == 10965181) {
    name = "Country Independent Artists";
    queryName = "cia";
  } else if (id == 9702666) {
    name = "Cash Creek TV";
    queryName = "cash";
  }

  // const deviceId = generateDeviceId();

  useEffect(() => {
    if (name && id) {
      dispatch(
        fetchVideos(
          `https://api.nctvn.com/getShowChannel?page=1&name=${queryName}&per_page=20`,
          id
        )
      );
      dispatch({
        type: IS_MAIN_APP,
        payload: false,
      });
    } else {
      dispatch(fetchVideos("https://api.nctvn.com/data?page=1", "main"));
      dispatch({
        type: IS_MAIN_APP,
        payload: true,
      });
    }
  }, []);

  useEffect(() => {
    // const token = Cookies.get("authToken");
    // if (token && !loading && videos) history.replace("/home");
    if (!loading && videos) {
      if (name) localStorage.setItem("appName", name);
      //   dispatch(selectTvShow(videos?.TS[0]));
      //   history.push(`/tv-episodes/${name}?layout=grid&showId=${id}`);
      // } else
      history.push("/home");
    }
  }, [loading, videos]);

  // const checkDevice = React.useCallback(() => {
  //   fetch(`https://api.nctvn.com/device/${deviceId}`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       window.history.pushState("backhandler", null, null);
  //       if (res.status === "SUCCESS") {
  //         console.log(res);
  //         dispatch(storeUser(res));
  //         getUserDetailsWithToken(res.user.email);
  //       } else {
  //         history.replace("/pair");
  //       }
  //     });
  // }, [deviceId]);

  // const getUserDetailsWithToken = (email) => {
  //   dispatch(fetchUserToken(email));
  // };

  // useEffect(() => {
  //   checkDevice();
  // }, [checkDevice]);

  return (
    <div className="bg-primary h-screen w-screen flex justify-center items-center">
      <img
        src={
          name
            ? name === "J Raleigh Jones Show"
              ? RaleighJones
              : name === "Country Independent Artists"
              ? Cia
              : CashCreek
            : MainImg
        }
        alt=""
        className="w-screen h-screen"
        // style={{ filter: "drop-shadow(0px 8px 1rem #f11286)" }}
      />
      <img
        src={loader}
        className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-20"
        alt=""
        style={{ bottom: "8%" }}
      />
    </div>
  );
}

export default SplashScreen;
